import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import BlankLayout from '../layouts/blank-layout.vue'

import EmbedLayout from '../layouts/embed-layout.vue'
import PHubLayout from '../layouts/phub-layout.vue'
import DefaultLayout from '../layouts/default-layout.vue'
import Page404Layout from '../layouts/page-404-layout.vue'
import PaymentLayout from '../layouts/payment-layout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

import { useAuthStore } from '@/stores/auth.store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },

  {
    name: '',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, [ ], 'not-found-simple') },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, [ 'admin', 'dashboard' ], 'not-found-simple') },
        component: () => import('../pages/ittms/dashboard/Dashboard.vue'),
      },
    ],
  },

  {
    name: 'payment',
    path: '/payment',
    component: PaymentLayout,
    children: [
      {
        name: 'qr',
        path: 'qr',
        component: () => import('@/pages/ittms/default/payment/PaymentQR.vue'),
      },
      {
        name: 'fulfill',
        path: 'fulfill',
        component: () => import('@/pages/ittms/default/payment/PaymentFulfill.vue'),
      },
      {
        name: 'complete',
        path: 'complete',
        component: () => import('@/pages/ittms/default/payment/PaymentComplete.vue'),
      },
    ]
  },

  {
    name: 'insta',
    path: '/insta',
    component: DefaultLayout,
    children: [
      {
        name: 'oauth/redirect',
        path: 'oauth/redirect',
        component: () => import('@/pages/ittms/default/insta/InstaRedirect.vue'),
      },
    ]
  },

  {
    name: 'confirmation',
    path: '/confirmation',
    component: DefaultLayout,
    children: [
      {
        name: 'confirmation-hospitality',
        path: 'hospitality',
        component: () => import('@/pages/ittms/default/confirmation/Hospitality.vue'),
      },
    ]
  },

  {
    name: 'player-wall',
    path: '/player-wall',
    component: DefaultLayout,
    children: [
      {
        name: 'player-wall/4k',
        path: '4k',
        component: () => import('@/pages/ittms/default/player_wall/PlayerWall.vue'),
      },
      {
        name: 'player-wall/hd',
        path: 'hd',
        component: () => import('@/pages/ittms/default/player_wall/PlayerWallHD.vue'),
      },
    ]
  },

  {
    name: 'stringer-api',
    path: '/stringer-api',
    component: DefaultLayout,
    children: [
      {
        name: 'stringer-api/start',
        path: 'start',
        component: () => import('@/pages/ittms/default/stringer/Start.vue'),
      },
      {
        name: 'stringer-api/finish',
        path: 'finish',
        component: () => import('@/pages/ittms/default/stringer/End.vue'),
      },
    ]
  },

  {
    name: 'open-practice-courts',
    path: '/open-practice-courts',
    component: DefaultLayout,
    children: [
      {
        name: 'open-practice-courts/list',
        path: 'list',
        component: () => import('@/pages/ittms/default/practice_courts/List.vue'),
      },
    ]
  },
  {
    name: 'open-shuttle-service',
    path: '/open-shuttle-service',
    component: DefaultLayout,
    children: [
      {
        name: 'open-shuttle-service/list',
        path: 'list',
        component: () => import('@/pages/ittms/default/shuttle_service/List.vue'),
      },
    ]
  },

  {
    name: 'accept_request',
    path: '/accept_request',
    component: DefaultLayout,
    children: [
      {
        name: 'accept-request',
        path: '',
        component: () => import('@/pages/ittms/default/handle_request/HandleRequest.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, [], '') },
      },
    ]
  },

  {
    name: 'decline_request',
    path: '/decline_request',
    component: DefaultLayout,
    children: [
      {
        name: 'decline-request',
        path: '',
        component: () => import('@/pages/ittms/default/handle_request/HandleRequest.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, [], '') },
      },
    ]
  },

  {
    name: 'notification',
    path: '/notification',
    component: AppLayout,
    children: [
      {
        name: 'notification/notify',
        path: 'notify',
        component: () => import('@/pages/ittms/user/notification/Notification.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'notification'], 'login') },
      },
    ]
  },

  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin'], 'not-found-simple') },
    children: [
      {
        name: 'admin_entry',
        path: '',
        redirect: { name: 'dashboard' },
      },
      {
        name: 'admin/user',
        path: 'user',
        component: () => import('@/pages/ittms/admin/general/user/User.vue'),
      },
      {
        name: 'admin/settings',
        path: 'settings',
        component: () => import('@/pages/ittms/admin/general/settings/Settings.vue'),
      },
    ]
  },

  {
    path: '/dashboard',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general', 'link'], 'not-found-simple') },
    children: [
      {
        name: 'dashboard',
        path: '',
        component: () => import('@/pages/ittms/admin/overview/Overview.vue'),
      },
    ]
  },
  {
    name: 'general',
    path: '/general',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general', 'link'], 'not-found-simple') },
    children: [
      {
        name: 'admin_entry',
        path: '',
        redirect: { name: 'dashboard' },
      },
      {
        name: 'general/overview',
        path: 'overview',
        component: () => import('@/pages/ittms/admin/general/overview/Overview.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general'], 'not-found-simple') },
      },
      {
        name: 'general/events',
        path: 'events',
        component: () => import('@/pages/ittms/admin/general/events/Events.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general'], 'not-found-simple') },
      },
      {
        name: 'general/members',
        path: 'members',
        component: () => import('@/pages/ittms/admin/general/members/Members.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general'], 'not-found-simple') },
      },
      {
        name: 'general/player',
        path: 'player',
        component: () => import('@/pages/ittms/admin/general/player/Player.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general'], 'not-found-simple') },
      },
      {
        name: 'general/champions',
        path: 'champions',
        component: () => import('@/pages/ittms/admin/general/champions/Champions.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general'], 'not-found-simple') },
      },
      {
        name: 'general/links',
        path: 'links',
        component: () => import('@/pages/ittms/admin/general/links/Links.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general', 'links'], 'not-found-simple') },
      },
    ]
  },
  {
    name: 'media',
    path: '/media',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'media'], 'not-found-simple') },
    children: [
      {
        name: 'media/general',
        path: 'general',
        component: () => import('@/pages/ittms/admin/media/general/MediaGeneral.vue'),
      },
    ]
  },

  {
    name: 'amvs',
    path: '/amvs',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'amvs'], 'not-found-simple') },
    children: [
      {
        name: 'amvs/overview',
        path: 'overview',
        component: () => import('@/pages/ittms/admin/amvs/overview/Overview.vue'),
      },
      {
        name: 'amvs/services',
        path: 'services',
        component: () => import('@/pages/ittms/admin/amvs/services/Services.vue'),
      },
      {
        name: 'amvs/requests',
        path: 'requests',
        component: () => import('@/pages/ittms/admin/amvs/requests/Requests.vue'),
      },
      {
        name: 'amvs/export',
        path: 'export',
        component: () => import('@/pages/ittms/admin/amvs/export/RequestExport.vue'),
      },
    ]
  },

  {
    name: 'payment-ext',
    path: '/payment-ext',
    component: AppLayout,
    children: [
      {
        name: 'payment/overview',
        path: 'overview',
        component: () => import('@/pages/ittms/admin/payment/overview/Overview.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'payment'], 'not-found-simple') },
      },
      {
        name: 'payment/transactions',
        path: 'transactions',
        component: () => import('@/pages/ittms/admin/payment/transactions/Transactions.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'payment'], 'not-found-simple') },
      },
    ]
  },

  {
    name: 'analytics',
    path: '/analytics',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'analytics'], 'not-found-simple') },
    children: [
      {
        name: 'analytics/general',
        path: 'general',
        component: () => import('@/pages/ittms/admin/analytics/general/Analytics.vue'),
      },
      {
        name: 'analytics/map',
        path: 'map',
        component: () => import('@/pages/ittms/admin/analytics/map/AnalyticsMap.vue'),
      },
      {
        name: 'analytics/visitor',
        path: 'visitor',
        component: () => import('@/pages/ittms/admin/analytics/visitor/VisitorAnalytics.vue'),
      },
    ]
  },

  {
    name: 'hotel',
    path: '/hotel',
    component: AppLayout,
    redirect: { name: 'hospitality/hotel' },
    children: [
      {
        name: '',
        path: '',
        redirect: { name: 'hospitality/hotel' },
      },
    ]
  },

  {
    name: 'hospitality',
    path: '/hospitality',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'hospitality', 'hospitality-hotel'], 'not-found-simple') },
    children: [
      {
        name: 'hospitality/reservations',
        path: 'reservations',
        component: () => import('@/pages/ittms/admin/hospitality/reservations/HospitalityReservations.vue'),
      },
      {
        name: 'hospitality/print',
        path: 'print',
        component: () => import('@/pages/ittms/admin/hospitality/print/HospitalityPrint.vue'),
      },
      {
        name: 'hospitality/hotel',
        path: 'hotel',
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'hospitality', 'hospitality-hotel'], 'not-found-simple') },
        component: () => import('@/pages/ittms/admin/hospitality/hotel/Hotel.vue'),
      },
    ]
  },

  {
    name: 'livescore',
    path: '/livescore',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'livescore'], 'not-found-simple') },
    children: [
      {
        name: 'livescore/overview',
        path: 'overview',
        component: () => import('@/pages/ittms/admin/livescore/overview/Overview.vue'),
      },
      {
        name: 'livescore/draws',
        path: 'draws',
        component: () => import('@/pages/ittms/admin/livescore/draws/Draws.vue'),
      },
      {
        name: 'livescore/draws/editor',
        path: 'draws/editor',
        component: () => import('@/pages/ittms/admin/livescore/draws/DrawEditor.vue'),
      },
      {
        name: 'livescore/order-of-plays',
        path: 'order-of-plays',
        component: () => import('@/pages/ittms/admin/livescore/order-of-plays/OoPs.vue'),
      },
      {
        name: 'livescore/order-of-plays/editor',
        path: 'order-of-plays/editor',
        component: () => import('@/pages/ittms/admin/livescore/order-of-plays/OoPEditor.vue'),
      },
    ]
  },

  {
    name: 'print',
    path: '/print',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'print'], 'not-found-simple') },
    children: [
      {
        name: 'print/player_pass',
        path: 'player-pass',
        component: () => import('@/pages/ittms/user/print/player_pass/PrintPlayerPass.vue'),
      },
    ]
  },

  {
    name: 'deposit',
    path: '/deposit',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'deposit'], 'not-found-simple') },
    children: [
      {
        name: 'deposit/scan',
        path: 'scan',
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'deposit'], 'not-found-simple') },
        component: () => import('@/pages/ittms/user/deposit/DepositScan.vue'),
      },
      {
        name: 'deposit/list',
        path: 'list',
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'deposit'], 'not-found-simple') },
        component: () => import('@/pages/ittms/user/deposit/DepositList.vue'),
      },
    ]
  },

  {
    name: 'user',
    path: '/u',
    component: AppLayout,
    redirect: { name: 'pra/registration' },
    children: [
      {
        name: 'pra',
        path: '',
        redirect: { name: 'pra/registration' },
      },
    ]
  },

  {
    name: '/pra',
    path: '/pra',
    component: AppLayout,
    children: [
      {
        name: 'pra',
        path: '',
        redirect: { name: 'pra/registration' },
      },
      {
        name: 'pra/import',
        path: 'import',
        component: () => import('@/pages/ittms/user/pra/import/Import.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'pra'], 'not-found-simple') },
      },
      {
        name: 'pra/registration',
        path: 'registration',
        component: () => import('@/pages/ittms/user/pra/registration/PRARegistration.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'pra'], 'not-found-simple') },
      },
      {
        name: 'pra/foto',
        path: 'foto',
        component: () => import('@/pages/ittms/user/pra/foto/PRAFoto.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'pra'], 'not-found-simple') },
      },
      {
        name: 'pra/export/picture',
        path: 'export/picture',
        component: () => import('@/pages/ittms/user/pra/export-player-picture/ExportPlayerPicture.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'pra'], 'not-found-simple') },
      },
      {
        name: 'pra/export/pass',
        path: 'export/pass',
        component: () => import('@/pages/ittms/user/pra/export-player-pass/ExportPlayerPass.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'pra'], 'not-found-simple') },
      },
      {
        name: 'pra/export/label',
        path: 'export/label',
        component: () => import('@/pages/ittms/user/pra/export-player-label/ExportPlayerLabel.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'pra'], 'not-found-simple') },
      },
    ]
  },

  {
    name: 'staff',
    path: '/staff',
    component: AppLayout,
    children: [
      {
        name: 'staff/staff',
        path: 'overview',
        component: () => import('@/pages/ittms/admin/staff/staff/Staff.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'staff'], 'not-found-simple') },
      },
      {
        name: 'staff/role',
        path: 'role',
        component: () => import('@/pages/ittms/admin/staff/roles/Role.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'staff'], 'not-found-simple') },
      },
      {
        name: 'staff/foto',
        path: 'foto',
        component: () => import('@/pages/ittms/admin/staff/foto/Foto.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'staff'], 'not-found-simple') },
      },
    ]
  },

  {
    name: 'coach',
    path: '/coach',
    component: AppLayout,
    children: [
      {
        name: 'coach/coach',
        path: 'overview',
        component: () => import('@/pages/ittms/admin/coach/coach/Coach.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'coach'], 'not-found-simple') },
      },
      {
        name: 'coach/foto',
        path: 'foto',
        component: () => import('@/pages/ittms/admin/coach/foto/Foto.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'coach'], 'not-found-simple') },
      },
    ]
  },
  {
    name: 'external',
    path: '/external',
    component: AppLayout,
    children: [
      {
        name: 'external/external',
        path: 'overview',
        component: () => import('@/pages/ittms/admin/external/external/External.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'external'], 'not-found-simple') },
      },
      {
        name: 'external/foto',
        path: 'foto',
        component: () => import('@/pages/ittms/admin/external/foto/Foto.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'external'], 'not-found-simple') },
      },
    ]
  },

  {
    name: 'shuttle-service',
    path: '/shuttle-service',
    component: AppLayout,
    children: [
      {
        name: 'shuttle-service/overview',
        path: 'overview',
        component: () => import('@/pages/ittms/user/shuttle-service/overview/Overview.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'shuttle-service'], 'not-found-simple') },
      },
      {
        name: 'shuttle-service/stations',
        path: 'stations',
        component: () => import('@/pages/ittms/user/shuttle-service/stations/Stations.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'shuttle-service'], 'not-found-simple') },
      },
      {
        name: 'shuttle-service/cars',
        path: 'cars',
        component: () => import('@/pages/ittms/user/shuttle-service/cars/Cars.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'shuttle-service'], 'not-found-simple') },
      },
      {
        name: 'shuttle-service/log',
        path: 'logs',
        component: () => import('@/pages/ittms/user/shuttle-service/log/Log.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'shuttle-service'], 'not-found-simple') },
      },
    ]
  },

  {
    name: 'stringer',
    path: '/stringer',
    component: AppLayout,
    children: [
      {
        name: 'stringer/stringer',
        path: '',
        component: () => import('@/pages/ittms/user/stringer/stringer/Stringer.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'stringer'], 'not-found-simple') },
      },
      {
        name: 'stringer/service',
        path: 'service',
        component: () => import('@/pages/ittms/user/stringer/service/Service.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'stringer'], 'not-found-simple') },
      },
      {
        name: 'stringer/export',
        path: 'export',
        component: () => import('@/pages/ittms/user/stringer/export/Export.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'stringer'], 'not-found-simple') },
      },
    ]
  },

  {
    name: 'practice-courts',
    path: '/practice-courts',
    component: AppLayout,
    children: [
      {
        name: 'practice-courts/overview',
        path: 'overview',
        component: () => import('@/pages/ittms/user/practice-courts/overview/Overview.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'practice-court'], 'not-found-simple') },
      },
      {
        name: 'practice-courts/schedule',
        path: 'schedule',
        component: () => import('@/pages/ittms/user/practice-courts/schedule/Schedule.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'practice-court'], 'not-found-simple') },
      },
    ]
  },

  {
    name: 'cms',
    path: '/cms',
    component: AppLayout,
    children: [
      {
        name: 'cms/articles',
        path: 'articles',
        component: () => import('@/pages/ittms/cms/article/Articles.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'cms'], 'not-found-simple') },
      },
      {
        name: 'cms/article-editor',
        path: 'article-editor',
        component: () => import('@/pages/ittms/cms/article/ArticleEditor.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'cms'], 'not-found-simple') },
      },
      {
        name: 'cms/categories',
        path: 'categories',
        component: () => import('@/pages/ittms/cms/categories/Categories.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'cms'], 'not-found-simple') },
      },
      {
        name: 'cms/tags',
        path: 'tags',
        component: () => import('@/pages/ittms/cms/tags/Tags.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'cms'], 'not-found-simple') },
      },
      {
        name: 'cms/media-library',
        path: 'media-library',
        component: () => import('@/pages/ittms/cms/media-library/MediaLibrary.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'cms'], 'not-found-simple') },
      },
    ]
  },
  
  {
    path: '/settings',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'settings'], 'not-found-simple') },
    children: [
      {
        name: 'settings',
        path: '',
        component: () => import('@/pages/ittms/settings/Settings.vue'),
      },
    ]
  },

  /*
  {
    path: '/database',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'database'], 'not-found-simple') },
    children: [ ]
  },
  */

  /*
  {
    name: 'analytics',
    path: '/analytics',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'analytics'], 'not-found-simple') },
    children: [
      {
        name: 'analytics/visitor',
        path: 'visitor',
        component: () => import('@/pages/ittms/analytics/visitor/VisitorAnalytics.vue'),
      },
    ]
  },
  */

  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin'], 'not-found-simple') },
    children: [
      {
        name: 'admin/user',
        path: 'user',
        component: () => import('@/pages/ittms/admin/user/User.vue'),
      },
      {
        name: 'admin/settings',
        path: 'settings',
        component: () => import('@/pages/ittms/admin/settings/Settings.vue'),
      },
    ]
  },

  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },

  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

const isAllowed = (to:any, from:any, next:any, permissions:Array<string>, reditectTo:string) => {
  const authStore = useAuthStore()

  if (to.name == 'login') {
    if(authStore?.token) {
      next({ name: '' })
    } else {
      next();
    }
  } else {
    let check = checkPermissions(permissions)

    if(check.allowed && authStore?.token) {
      next();
    } else {
      if(authStore?.token) {
        next({ name: reditectTo })
      } else {
        next({ name: 'login' })
      }
    }
  }
}

const checkPermissions = (permissions:Array<string>) => {
  const authStore = useAuthStore()

  // return { allowed: true, redirectTo: null }

  if(authStore?.user?.role?.name == 'admin') {
    return { allowed: true, redirectTo: null };
  }

  if(permissions.length == 0) {
    return { allowed: true, redirectTo: null };
  } else if(permissions.length > 0) {
    if(authStore?.token && authStore?.user?.role) {
      for(let permission of permissions) {
        if(authStore?.user?.role?.permissions.map((data: any) => { return data.name }).indexOf(permission) > -1) {
          return { allowed: true, redirectTo: null };
        }
      }

      return { allowed: false, redirectTo: '' };
    } else {
      return { allowed: false, redirectTo: 'login' };
    }
  } else {
    return { allowed: false, redirectTo: '' };
  }
}

router.beforeEach(async (to:any, from:any, next:any) => {
  const authStore = useAuthStore()

  if (to.name == 'login') {
    if(authStore?.token) {
      next();
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
